<template>
  <div class="v-toggle d-flex flex-row align-center">
    <span
        class="text-no-wrap"
        :class="!value ? color+ '--text' : ''"
        @click="$emit('input', false)">
      {{ labelOff }}
    </span>
    <v-switch
        :color="color"
        :value="value"
        hide-details
        @change="$emit('input', !value)"
        class="mx-4"
    >

    </v-switch>
    <span
        class="text-no-wrap"
        :class="value ? color+'--text' : ''"
        @click="$emit('input', true)">
      {{ labelOn }}
    </span>
  </div>
</template>

<script>
export default {
  name: "VToggle",
  props: {
    labelOff: String,
    labelOn: String,
    value: Boolean,
    color: {
      type: String,
      default() {
        return "primary"
      }
    }
  }
}
</script>

<style scoped>
  .v-toggle > span {
    cursor: pointer;
  }
</style>